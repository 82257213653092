<template>
  <div class="Detail">
    <shopHeader :shopId="shop_id" v-if='shop_type==1||!shop_type'></shopHeader>
    <shopHeaderSupplier :shopId="shop_id" v-else-if="shop_type==2"></shopHeaderSupplier>
    <shopHeader4s :shopId="shop_id" v-else-if='shop_type==3'></shopHeader4s>
 

    <vbar :shopId='shop_id'></vbar>

    <div class="content_container ">

      <shopBanner :shopId="shop_id"></shopBanner>

      <shopCoupon :shopId="shop_id"></shopCoupon>

      <shopIndexProduct :shopId="shop_id"></shopIndexProduct>

    </div>
  </div>
</template>
<script>
import shopHeader4s from "@/components/shop-header-4s";
import shopHeader from "@/components/shop-header";
import shopHeaderSupplier from "@/components/shop-header-supplier";

import shopBanner from "@/components/shop-banner";
import shopCoupon from "@/components/shop-coupon";
import shopIndexProduct from "@/components/shop-index-product";


import vbar from "@/components/vbar";

export default {
  components: {
    shopHeader,
    shopHeader4s,
    shopIndexProduct,
    shopHeaderSupplier,
    vbar,
    shopBanner,
    shopCoupon
  },

  data() {
    return {

      shop_id: '',
      shop_type: ''

    };
  },
  computed: {},
  mounted() {
    this.shop_id = this.$route.query.id;
    if (this.shop_id) {
      this.getShop()
    }


  },
  methods: {

    getShop() {

      this.$get("seller/shop/" + this.shop_id).then(res => {
        if (!res.enabled) {
          this.$router.push("/close");
        }

        this.shop_type = res.shop_type;

      });
    },


    // searchAll() {
    //   this.num = 0;
    //   this.searchData.sale_num_pr = undefined;
    //   this.searchData.price_pr = undefined;
    // },
    // searchSale() {
    //   this.num = 1;
    //   this.searchData.sale_num_pr = "desc";
    //   this.searchData.price_pr = undefined;
    // },

    // searchPrice() {
    //   this.num = 2;
    //   this.searchData.sale_num_pr = undefined;
    //   this.searchData.price_pr = "asc";
    // },



  },
  created() { }
};
</script>

 
 