<template>

  <div class="banner" v-if='banner.length'>
    <el-carousel height="500px">
      <el-carousel-item v-for='(item,index) in banner' :key='index'>
        <h3 class="small">
          <a :href="item.ad_link" target="_blank">
            <img :src='$config.baseUrl+item.get_files.files_path'>
          </a>
        </h3>
      </el-carousel-item>
    </el-carousel>

  </div>

</template>
<script>

export default {
  data() {
    return {
      banner: []
    };
  },
  mounted() { },
  components: {},
  props: {
    shopId: [Number, String]
  },
  watch: {
    shopId: {
      immediate: true,
      handler() {
        console.log('this.shopId111', this.shopId)
        if (this.shopId) {
          this.getBanner();
        }
      }
    }
  },

  methods: {
    getBanner() {
      this.$get("seller/shopAd", { shopId: this.shopId, pid: 9 }).then(res => {
        this.banner = res.items.data;
      });
    }
  },

  computed: {}
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

.banner {
  width: 100%;
  img {
    width: 100%;
    height: 500px;
  }
}
</style>
