<template>

  <div v-if="couponList.length > 0" class="couponList">
    <el-carousel :interval='6000' height='200px'>
      <el-carousel-item v-for='(coupon, cindex) in couponList' :key='cindex'>
        <div class="coupon" v-for="(item, index) in coupon" :key="index">
          <ul>
            <li class="left">
              ￥<span> {{ item.money }}</span>
            </li>
            <li class="center">
              <div class="c">
                <p class="name">{{ item.name }}</p>
                <p>优惠券使用条件 {{item.condition}}元</p>
                <p style="font-size: 10px">
                  {{ item.use_start }}至{{ item.use_end }}
                </p>
              </div>

            </li>
            <li class="right"><button @click="receive(item)">领取</button></li>
          </ul>
        </div>
      </el-carousel-item>
    </el-carousel>

  </div>

</template>
<script>
export default {
  data() {
    return {
      couponList: []
    };
  },
  mounted() { },
  components: {},
  props: {
    shopId: [Number, String]
  },
  watch: {
    shopId: {
      immediate: true,
      handler() {
        if (this.shopId) {
          this.getCoupon();
        }
      }
    }
  },

  methods: {

    //领取优惠券
    receive(item) {
      this.$post("home/shop_coupon_receive", {
        coupon_id: item.id,
        member_id: this.$store.state.member.id
      }).then(res => {
        this.$message.success("领取成功");
      });
    },
    //获取优惠券

    getCoupon() {
       this.$get("home/shop_coupon", { shop_id: this.shopId }).then(res => {
        let data = res.items.data;
        let coupons = [];
        let count = (data.length - 1) / 3 + 1;
        for (let i = 0; i < count; i++) {
          let array = data.slice(i * 3, (i + 1) * 3);
          if (array.length) {
            coupons.push(data.slice(i * 3, (i + 1) * 3));
          }
        }
        this.couponList = coupons;
      });
    }
  },

  computed: {}
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

.couponList {
  width: 100%;
  box-sizing: border-box;
  overflow-x: scroll;

  white-space: nowrap;
  .coupon {
    color: #666;
    height: 100%;
    display: inline-block;
    width: 32%;
    box-sizing: border-box;
    margin-right: 1%;
    white-space: nowrap;
    ul {
      border: 10px solid #fff;
      padding: 10px;
      box-sizing: border-box;
      box-sizing: border-box;
      background: #fff4f2;

      height: 100%;
      @include space-between;

      .left {
        color: red;
        width: 80px;
        @include center;
        span {
          font-weight: bold;
          font-size: 24px;
        }
      }
      .center {
        padding: 10px;
        p {
          margin-bottom: 10px;
          width: 100%;
        }
        flex: 1;
        width: 150px;
        @include center;
        .name {
          font-weight: bold;
          font-size: 14px;
        }
      }
      .right {
        width: 100px;
        @include center;
        button {
          width: 90px;
          height: 30px;
          border-radius: 20px;
          border: 0;
          @include center;
          background: red;
          color: #fff;
        }
      }
    }
  }
}
</style>
