<template>

  <div>
    <template v-if='recomendList.length'>
      <p class="title">
        <img src="@/assets/images/tit6.png">
      </p>
      <div class="pro-list">

        <ol>
          <li
            class="item"
            v-for="(item, index) in recomendList"
            :key="index"
          >
            <div class="item-img-wraper">
              <el-image
                lazy
                class="item-img"
                :src="$config.baseUrl + item.files_path"
                @click="toDetail(item)"
              />
            </div>
            <p
              class="item-name"
              @click="toDetail(item)"
            >
              {{ item.goods_name }}
            </p>
            <div
              class="item-price"
              v-if="item.isprice == 2"
            >
              <span>
                ￥{{ parseFloat(item.min_price).toFixed(2) }} - ￥{{
                    parseFloat(item.max_price).toFixed(2)
                  }}
              </span>
            </div>
            <div
              class="item-price"
              v-if="item.isonly == 1 && item.isprice == 1 && item.num_price == null"
            >
              <span>￥{{ parseFloat(item.sales_price/ 100).toFixed(2)  }}</span>
            </div>

            <div
              class="item-price"
              v-if="item.isonly == 1 && item.isprice == 1 && item.num_price"
            >
            <span>￥{{ JSON.parse(item.num_price)[0].price }} ~ ￥{{ JSON.parse(item.num_price)[JSON.parse(item.num_price).length-1].price }}</span>
            </div>
            
            <div
              class="item-price"
              v-if="item.isonly == 2 && item.isprice == 1 && item.num_price == null"
            >
              <span>￥{{ item.spec_min_price}}起</span>
            </div>

            <div
              class="item-price"
              v-if="item.isonly == 2 && item.isprice == 1 && item.num_price"
            >
              <span>￥{{ JSON.parse(item.num_price)[0].price[0].price }} 以下</span>
            </div>

          </li>
        </ol>
        <p class="more">
          <el-button
            :loading="recommendloading"
            @click="recommendClick"
            v-if="recommendMore"
          >查看更多</el-button>

        </p>

      </div>
    </template>

    <template v-if='newList.length'>
      <p class="title">

        <img src="@/assets/images/tit5.png">
      </p>
      <div class="pro-list">
        <ol>
          <li
            class="item"
            v-for="(item, index) in newList"
            :key="index"
          >
            <div class="item-img-wraper">
              <el-image
                lazy
                class="item-img"
                :src="$config.baseUrl + item.files_path"
                @click="toDetail(item)"
              />
            </div>
            <p
              class="item-name"
              @click="toDetail(item)"
            >
              {{ item.goods_name }}
            </p>
            <div
              class="item-price"
              v-if="item.isprice == 2"
            >
              <span>
                ￥{{ parseFloat(item.min_price).toFixed(2) }} - ￥{{
                    parseFloat(item.max_price).toFixed(2)
                  }}
              </span>
            </div>
            <div
              class="item-price"
              v-if="item.isonly == 1 && item.isprice == 1 && item.num_price == null"
            >
              <span>￥{{ parseFloat(item.sales_price/ 100).toFixed(2)  }}</span>
            </div>

            <div
              class="item-price"
              v-if="item.isonly == 1 && item.isprice == 1 && item.num_price"
            >
            <span>￥{{ JSON.parse(item.num_price)[0].price }} ~ ￥{{ JSON.parse(item.num_price)[JSON.parse(item.num_price).length-1].price }}</span>
            </div>

            <div
              class="item-price"
              v-if="item.isonly == 2 && item.isprice == 1 && item.num_price == null"
            >
              <span>￥{{ item.spec_min_price}}起</span>
            </div>

            <div
              class="item-price"
              v-if="item.isonly == 2 && item.isprice == 1 && item.num_price"
            >
              <span>￥{{ JSON.parse(item.num_price)[0].price[0].price }} 以下</span>
            </div>

          </li>
        </ol>
        <p class="more">
          <el-button
            :loading="newloading"
            @click="newClick"
            v-if="newMore"
          >查看更多</el-button>

        </p>

      </div>

    </template>

  </div>

</template>
<script>
export default {
  data() {
    return {
      banner: [],
      kefu_qq: "",
      star: 2,
      shopSort: [],
      show1: false,
      loading: true,

      searchData: {
        shop_id: undefined,
        sale_num_pr: undefined,
        price_pr: undefined,
        goodsname: undefined
      },
      newpage: 1,
      newloading: false,
      newMore: false,
      recommendpage: 1,
      recommendloading: false,
      recommendMore: false,

      num: 0,
      num2: 1,
      listIndex: 0,
      name: "",
      newList: [],
      recomendList: [],
      shopInfo: "",
      car: false,

      tipsQuantity: 0,

      display: false
    };
  },
  mounted() {},
  components: {},
  props: {
    shopId: [Number, String]
  },
  watch: {
    shopId: {
      immediate: true,
      handler() {
        if (this.shopId) {
          this.getShop();
          this.getNewList(true);
          this.getRecomendList(true);
        }
      }
    }
  },

  methods: {
    //跳转详情页
    toDetail(item) {
      let routerJump = this.$router.resolve({
        path: "/detail",
        query: { id: item.goods_id, brand: item.get_brand || {}.name }
      });
      window.open(routerJump.href, "_blank");

      // this.$router.push({
      //   path: "/detail",
      //   query: { id: item.goods_id, brand: item.get_brand || {}.name }
      // });
    },
    //获取店铺信息
    getShop() {
      this.$get("seller/shop/" + this.shopId).then(res => {
        if (!res.enabled) {
          this.$router.push("/close");
        }
        this.shopInfo = res;
        this.searchData.shopid = res.shop_id;
      });
    },
    // 最新产品加载更多
    newClick() {
      this.newpage++;
      this.newloading = true;
      this.getNewList();
    },
    recommendClick() {
      this.recommendpage++;
      this.recommendloading = true;
      this.getRecomendList();
    },
    //获取最新产品
    getNewList(reload) {
      if (reload) this.newpage = 1;
      this.$get("home/goods", {
        shopid: this.shopId,
        limit: 20,
        page: this.newpage
      })
        .then(res => {
          this.newList = this.newList.concat(res.items.data);
          this.newMore = this.newList.length < res.items.total;
        })
        .finally(() => {
          this.newloading = false;
        });
    },
    //获取推荐产品
    getRecomendList() {
      this.$get("home/goods", {
        shopid: this.shopId,
        shop_goods_label: 1,
        limit: 20,
        page: this.recommendpage
      })
        .then(res => {
          this.recomendList = this.recommenList.concat(res.items.data);
          this.recommenMore = this.recommenList.length < res.items.total;
        })
        .finally(() => {
          this.recomendloading = false;
        });
    }
  },

  computed: {}
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

.el-carousel__button {
  background: #ccc;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  line-height: 500px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.title {
  text-align: center;
  padding: 20px;

  img {
    width: 400px;
  }
}
.center {
  @include center;
  height: 100%;
  width: 100%;
  i {
    font-size: 25px;
  }
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/*定义滚动条轨道*/
::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  background-color: #ccc;

  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
::v-deep .el-carousel__item {
  background: none !important;
}

.pointer {
  background: #fe6925;

  color: #fff;
  border-radius: 5px;
}
.topbar {
  background: #fff;
  width: 100%;
  text-align: center;
  color: #757575;
  border-bottom: #ccc 1px solid;

  height: 50px;
  line-height: 50px;

  ul {
    padding-top: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    @include row;
    li {
      cursor: pointer;

      @include center;
      @include row;
      flex-wrap: nowrap;
      height: 30px;
      line-height: 30px;
      width: 80px;
      text-align: center;
      margin: 0 20px;
      @include center;
    }
  }
}
.Detail {
  background: #f5f5f5;
}

.banner {
  width: 100%;
  img {
    width: 100%;
    height: 500px;
  }
}
.product-info {
  background: #fff;
  padding: 20px;
  line-height: 25px;
  text-align: left;
  margin-bottom: 20px;
}
.pro-list {
  background: #fff;
  ol::after {
    clear: both;
    display: block;
    content: "";
  }
  .more {
    text-align: center;
    padding: 20px;
  }

  ul {
    background: #f0f0f0;
    width: 300px;

    li {
      padding: 10;
    }
  }
  ol {
    flex: 1;
    padding: 20px 10px;
    margin: 0 -10px;

    .item {
      background: #fff;
      box-sizing: border-box;
      margin: 0 10px;
      text-align: center;
      width: 220px;
      height: 300px;
      overflow: hidden;
      margin-bottom: 10px;
      float: left;
      position: relative;
      .item-img-wraper {
        overflow: hidden;

        height: 220px;

        box-sizing: border-box;

        .item-img {
          width: 100%;
          height: 100%;
        }
      }
      .shop_name {
        text-align: left;
        line-height: 30px;
        @include space-between;

        p {
          color: #999;
          width: 80%;
          @include ellipsis;
        }
      }
      .item-name {
        text-align: left;
        padding: 5px 0;
        font-size: 14px;
        color: #757575;
        line-height: 18px;
        overflow: hidden;
        height: 43px;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .item-price {
        font-size: 24px !important;
        @include space-between;
        line-height: 18px;
        span {
          font-size: 16px !important;
        }
        color: red;
      }
      .el-image {
        width: 100%;
        height: 220px;
      }
      img {
        width: 100%;
        height: 220px;
      }
    }
  }
  ol:after {
    content: "";
    width: 30%;
  }
}
</style>
